import React from "react";
import { ContactForm } from "@/components/contact-form";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";
import { ApplicationForm } from "@/components/application-form";

const FullStackEngineerPage = () => {
  return (
    <>
      <GatsbySeo
        title="Fullstack Enginner - Careers | Tectra Technologies"
        canonical="https://www.tectratechnologies.com/careers/fullstack-enginner/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/careers/fullstack-enginner/",
          title: "Fullstack Enginner - Careers | Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white mt-20 lg:mt-24">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-normal captalize tracking-tight">
              Full Stack Engineer
            </h2>

            <p className="text-lg font-poppins font-thin text-black">
              Chennai, IN - Full Time
            </p>

            <div className="mt-6">
              <ApplicationForm job_type="Full Stack Engineer" />
            </div>
          </div>
        </div>

        <div className="bg-white mt-4">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="grid lg:grid-cols-12">
              <div className="lg:col-span-12">
                <h5 className="mb-4 mt-2 font-medium underline text-lg">
                  Requirements:-
                </h5>

                <ul className="list-disc text-black ml-8">
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Minimum 2 years of experience into development 🧑‍💻in
                    technologies like Node/ PHP/ NEXT JS/ React JS/ React Native
                    technologies.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Should have sound knowledge of database MongoDB/ MySql and
                    latest technologies (PHP/Node JS), common web front-end
                    frameworks such as React/Angular
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Should be creative, smart, solution oriented and capable of
                    solving any technical challenges.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Ensure the technical feasibility of UI/UX designs.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Good communication skills.
                  </li>
                </ul>

                <h5 className="mb-4 mt-6 font-medium underline text-lg">
                  Job Responsibilities:-
                </h5>

                <ul className="list-disc mb-0 ml-8">
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Define technical specifications of the project.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Build reusable components and applications.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Developing secure, high performance and scalable front end
                    as well as backend applications.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Testing and delivering a high-quality error free application
                    within a predefined time schedule.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Work collaboratively with cross functional teams to deliver
                    cutting edge solutions that meet client’s expectations.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Learn quickly and use modern framework and technologies in a
                    fast-paced environment.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Train and mentor colleagues and become a Ninja 🥷 of this
                    domain.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Drive the project independently right from Project
                    Conceptualization Stage till the deployment stage.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Expert in problem solving and available when needed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FullStackEngineerPage;
